;(function ( $, window, document, undefined ) {

    // Inspiré du code http://r.cheron.free.fr/GMaps3.html
	
	
    var pluginName = "routeMaps",
        defaults = {
        lat: 0,
        lng: 0,
        zoom: 8,
        startLat: null,
        startLng: null,
        //mapTypeId: google.maps.MapTypeId.ROADMAP,
        draggableCursor: 'crosshair',
        etapeIcon: null,
        markers: [],
        change: null
    };

    function Plugin ( element, options ) {
        this.element = element;
        this.$element = $(this.element);
        this.settings = $.extend( {}, defaults, options );
        this._defaults = defaults;
        this._name = pluginName;

        this.infoWindow = null;
        this.map = null;
        this.centerPoint = null;
        this.geocoder = null;
        this.etapes = [];
        this.bounds = null; // rectangle de l'ensemble des étapes
        this.path = null;
        this.startPoint = null;
        this.distance = 0;
		this.steps = null;

        this.init();
    }

    $.extend(Plugin.prototype, {
        init: function () {
            this.centerPoint = new google.maps.LatLng(this.settings.lat, this.settings.lng);
            if (this.settings.startLat && this.settings.startLng) {
                this.startPoint = new google.maps.LatLng(this.settings.startLat, this.settings.startLng);
            }
            this.geocoder = new google.maps.Geocoder();
            this.loadMap();
            if (this.startPoint) {
                this.addEtape(this.startPoint, 0);
            }
            this.loadMarkers();
        },
        loadMap: function () {
            var mapOptions = {
                center: this.centerPoint,
                zoom: this.settings.zoom,
                mapTypeId: this.settings.mapTypeId,
                draggableCursor: this.settings.draggableCursor
            };
            this.infoWindow = new google.maps.InfoWindow();
            this.map = new google.maps.Map(this.$element.get(0), mapOptions);
            // Evenement pour ajouter les escales
            google.maps.event.addListener(this.map, "click", function(event) {
                self.addEtape(event.latLng);
            });
        },
        loadMarkers: function () {
            self = this;
            $.each(this.settings.markers, function(i, data) {
                var myLatlng = new google.maps.LatLng(data.lat, data.lng);
                var marker = new google.maps.Marker({
                    position: myLatlng,
                    map: self.map,
                    icon: { url: data.icon },
                    title: data.title
                });
                google.maps.event.addListener(marker, "click", function (e) {
                    self.infoWindow.setContent(data.description);
                    self.infoWindow.open(self.map, marker);
                });
            });
        },
        addEtape: function (point, opacity) {
            self = this;

            opacity = typeof opacity !== 'undefined' ? opacity : 1;

            var options = {
                position: point,
                map: this.map,
                opacity: opacity,
                zIndex: 0,
                title: 'point ' + this.etapes.length,
            };
            if (opacity != 0) {
                options = $.extend( {}, options, {draggable: true });
            }
            if (this.settings.etapeIcon) {
                options = $.extend( {}, options, {icon: this.settings.etapeIcon });
            }
            var marker = new google.maps.Marker(options);
            /* Désactivation car option payante maintennat
            this.geocoder.geocode({'latLng': point}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    google.maps.event.addListener(marker,"click", function(event) {
                        self.infoWindow.setContent(results[0].formatted_address);
                        self.infoWindow.open(self.map, marker);
                    });
                }
            });*/
            google.maps.event.addListener(marker, "dragend", function(event) {
                $.each(self.etapes, function(i, etape) {
                    if (etape.title == marker.title) {
                        self.etapes[i] = marker;
                    }
                });
                self.drawRoute();
                self.settings.change(self); // Callback
            });
            this.etapes.push(marker);

            this.ajusteMap(point);

            this.drawRoute();

            this.settings.change(this); // Callback
        },
        drawRoute: function () {
            var distance = 0;
			var steps = 0;
            var etapes = this.etapes;
            for (var i = 0, c = this.etapes.length; i < c-1; i++)
            {
                distance = distance + this.orthodromy(
                        this.etapes[i].getPosition().lat(),
                        this.etapes[i].getPosition().lng(),
                        this.etapes[i+1].getPosition().lat(),
                        this.etapes[i+1].getPosition().lng()
                    );
				steps = this.orthodromy(
                        this.etapes[i].getPosition().lat(),
                        this.etapes[i].getPosition().lng(),
                        this.etapes[i+1].getPosition().lat(),
                        this.etapes[i+1].getPosition().lng()
                    );
            }
            this.distance = distance;
			this.steps = steps;
            if (this.path) {
                this.path.setMap(null);
            }
			
            var flightPlanCoordinates = [];
            for (var i = 0; i < this.etapes.length; i++) {
                flightPlanCoordinates[i] = this.etapes[i].getPosition();
            }
            this.path = new google.maps.Polyline({
                path: flightPlanCoordinates,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                geodesic: true
            });
            this.path.setMap(this.map);
        },
        orthodromy: function (lat1, lng1, lat2, lng2) {
            lat1 = Math.PI * lat1 / 180;
            lat2 = Math.PI * lat2 / 180;
            lng1 = Math.PI * lng1 / 180;
            lng2 = Math.PI * lng2 / 180;
            if (lat1 == 0) lat1 = lat2;
            if (lng1 == 0) lng1 = lng2;
            var prod = Math.sin(lat2) * Math.sin(lat1) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
            return 6378.137 * Math.acos(prod) / 1.852;
        },
        deleteEtapes: function () {
            for (var i = 0; i < this.etapes.length; i++) {
                this.etapes[i].setMap(null);
            }
            this.path.setMap(null);
            this.distance = 0;
			this.steps = 0;
            this.bounds = null;
            this.etapes = [];
            if (this.startPoint) {
                this.addEtape(this.startPoint, 0);
            }
            this.settings.change(this); // Callback
        },
        ajusteMap: function (point) {
            if (this.bounds == null) {
                this.bounds = new google.maps.LatLngBounds(this.centerPoint, point);
            } else {
                this.bounds.extend(point);
            }
            if (this.etapes.length > 1) {
                // Recentre la map
                this.map.fitBounds(this.bounds);
            }
        }
    });

    $.fn[ pluginName ] = function ( options ) {
        this.each(function() {
            if ( !$.data( this, "plugin_" + pluginName ) ) {
                $.data( this, "plugin_" + pluginName, new Plugin( this, options ) );
            }
            else if ($.isFunction(Plugin.prototype[options])) {
                $.data(this, 'plugin_' + pluginName)[options]();
            }
        });

        return this;
    };

})( jQuery, window, document );
