// jQuery assets
import '/javascript/partials/jquery.js';
import '/javascript/partials/jquery-datepicker.js';
import '/javascript/partials/jquery-magnificpopup.js';
import '/javascript/partials/jquery-parallax.js';
import '/javascript/partials/jquery-parsleyjs.js';
import '/javascript/partials/jquery-slick.js';
import '/javascript/partials/jquery-stickykit.js';

// Bootstrap assets
import '/javascript/partials/bootstrap.js';
import '/javascript/partials/bootstrap-popover.js';
import '/javascript/partials/bootstrap-selectpicker.js';
import '/javascript/partials/bootstrap-sliderpicker.js';
import '/javascript/partials/bootstrap-tooltip.js';

// Google assets
//import '/javascript/partials/google-map.js';

// Other assets
import '/javascript/partials/aos.js';
import '/javascript/partials/jquery.routeMaps.js';

// Modules
import '/javascript/modules/01-navigation.js';
import '/javascript/modules/02-helper.js';
import '/javascript/modules/03-components.js';

// SCSS
import '/scss/global.scss';

import '/javascript/partials/bootstrap-modal.js';
