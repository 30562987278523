// JS dependency
var bootstrapslider = require('bootstrap-slider');

// CSS dependency
import '/../../node_modules/bootstrap-slider/dist/css/bootstrap-slider.css';

// Initialisation
$("#range").slider({
  tooltip: 'hide'
});

$("#range").on("slide", function(slideEvt) {
  var sliderValue = slideEvt.value + '';
  var array = sliderValue.split(',');
  $(this).next().find('.value.min .txt').text(array[0]);
  $(this).next().find('.value.max .txt').text(array[1]);
});
