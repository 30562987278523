// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyDZ6JX5u1oDr00EwJgD9xv2U_CQh4hDlCA';

// Initialisation
function f_modalLoad(href) {
  $('#modal .modal-body').html();
  $('body').addClass('modal-loading');
  $('#modal .modal-content').load(href + ' #modal-content', function(response, status, xhr) {
    if (status == "error") {
      var msg = "Sorry but there was an error: ";
      alert(msg + xhr.status + " " + xhr.statusText);
    } else {
      $('#modal').modal();
      $('.trigger-modal-inner').on('click', function() {
        $('#modal .modal-dialog').removeClass('modal-lg modal-sm modal-dialog-centered modal-contact');
        var href = $(this).attr('href');
        var size = $(this).data('size');
        var align = $(this).data('align');
        var classCss = $(this).data('class');
        $('#modal .modal-dialog').addClass(size).addClass(align);
        $('body').addClass(classCss);
        f_modalLoad(href);
        $('[data-toggle="tooltip"]').tooltip();
        return false;
      });
    }
    $('body').removeClass('modal-loading');
  });
}

$('.trigger-modal').on('click', function() {
  $('#modal .modal-dialog').removeClass('modal-lg modal-sm modal-dialog-centered modal-contact');
  var href = $(this).attr('href');
  var size = $(this).data('size');
  var align = $(this).data('align');
  var classCss = $(this).data('class');
  $('#modal .modal-dialog').addClass(size).addClass(align);
  $('body').addClass(classCss);
  f_modalLoad(href);
  $('[data-toggle="tooltip"]').tooltip();

  if(classCss==='map'){

    GoogleMapsLoader.load(function(google) {

        function initMap() {
            //declaration des points dans un prototype pour reinitialisation apres suppression du trajet
            function step() {}
            step.prototype = { a:-1, b:0 };
            var step = new step();
            delete step.a;
            delete step.b;


            $.getJSON('/carte/escale/alljson').done(function(markers) {

                $('#maps-google').routeMaps({
                    'lat': 14.469726905135598,
                    'lng': -60.86700439453125,
                    'startLat': 14.469726905135598,
                    'startLng': -60.86700439453125,
                    'markers': markers,
                    'etapeIcon': {'url': '/theme/images/icons/maps_bateau.png' },
                    'change': function(routemaps) {
                        $("#maps-google-distance").html(routemaps.distance.toFixed(3));
                        $("#maps-google-distance-etape").append('* Distance point '+ ++step.a + ' à point ' + ++step.b + ' : <strong>' +  Math.ceil(routemaps.steps.toFixed(3)) + ' milles nautique </strong>(' + Math.round((routemaps.steps.toFixed(1) / 75) * 10)/10 +' jour(s))<br>');
                        $("#maps-google-duree").html(Math.ceil(routemaps.distance / 75));
                    }
                });
                $("#delete_etapes").click(function() {
                    $('#maps-google').routeMaps('deleteEtapes');
                    $("#maps-google-distance-etape").empty();
                    $("#maps-google-distance-etape").html();
                    delete step.a;
                    delete step.b;
                });

                //initialisation des etapes au chargement de la carte
                $("#maps-google-distance-etape").empty();
                $("#maps-google-distance-etape").html();
                delete step.a;
                delete step.b;
            });

        }

      setTimeout(function(){
        initMap();
      }, 1000);
    });
  }

  return false;
});
